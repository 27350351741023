<template>
  <div v-if="this.list" class="tabs" :class="$root.edit.can_edit_city ? '': 'hide'">
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <!-- <Search :filterNone="'none'" :search2="searchText" :isActiveFilter="btnFilter" @filter="filter.flag = false" @inputchange="search" v-if="list !== null"/> -->
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false" v-if="list !== null" />
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list">
        <li 
          :key="index"
          v-for="(item, index) in listNew"
          :data-id="item.id"   
          @click.prevent="openPopup(item, 'update')"  
        >
          <h4>{{item.name}}</h4>
          <p>{{item.country}}</p>
          <div class="icon icon-arrow-right"></div>
        </li>
      </ul>
      <div v-if="$root.add.can_add_city" @click.prevent="openPopup('', 'create')"  class="add-plus"></div>
      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="closePopup('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="closePopup('close')"></a>
        <h2>{{$root.langText.changes.addCity}}</h2>
        <div class="form__input">
          <input 
            type="text" 
            v-model="name" 
          >
          <span>{{$root.langText.input.name}}</span>
        </div>
        <Autocomplete
          :data="{name: $root.langText.directory.country, mod: 'countries', key: 'countries', input: countries, inputId: countries_id}" 
          @result="autocompleteResult" 
        />
        <p v-if="emty" style="color: red; padding: 0px 0 15px">{{$root.langText.changes.enterData}}</p>
        <div class="pop-up__btn pop-up__btn--one">
          <a class="btn btn-primary" @click.prevent="closePopup('save')">{{$root.langText.save}}</a>
        </div>
      </div>
    </div>
  </div>
</template>







<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"
import Autocomplete from "@/components/app/Autocomplete"


export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Міста', info: ''}
    ],
    //popup
    name: '',
    countries: '',
    countries_id: '',
    id: '',
    emty: false,
    popup: false,
    mod: '',
    searchText: '',


    params: {
      start: 0, 
      limit: 20,
    },
    filter: {
      flag: true,
      mod: 'city',
      screen: true
    },
    btnFilter: false,
    list: null,
    listNew: null,
    arrFilters: '',

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    quantityList: '',
    listNot: false
  }),




  async created() {

    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    if(efe['search']) {
      this.searchData = 'search=' + efe['search']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)
    

    //title
    this.globalTitle[0].title = this.$root.langText.directory.city
    this.$root.title = this.globalTitle

    //get list
    const res = await this.$store.dispatch('postFiltersCity', {params: this.params, data: this.arrFilters})
    this.list = res.data;
    if(this.list){
      this.listNew = res.data.list;
      this.quantityList = res.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    }
  },
  methods: {
    autocompleteResult(data){
      this[data.input] = data.name
      this[data.input + '_id'] = data.id
    },
    openPopup(data, mod){
      this.popup = true
      if(mod == 'update'){
        this.name = data.name
        this.countries = data.country
        this.countries_id = data.country_id
        this.mod = 'update'
        this.id = data.id
      } else{
        this.mod = 'create'
      }
    },
    async closePopup(mod){
      if(mod == 'save'){
        var data = {
          name: this.name,
          country_id: this.countries_id
        }
        if(this.name && this.countries_id){
          if(this.mod == 'update'){
            data.id = this.id
            await this.$store.dispatch('updateCity', data)
          } else{
            await this.$store.dispatch('addCity', data)
          }
          this.searchData = 'search='+this.name
          this.searchText = this.name
          this.arrFilters = this.searchData
          var res = await this.$store.dispatch('postFiltersCity', {params: this.params, data: this.arrFilters})
          this.listNew = res.data.list
          this.quantityList = res.data.total_num
          this.emty = false

          this.name = ''
          this.countries = ''
          this.countries_id = ''
          this.popup = false

        } else{
          this.emty = true
        }
      } else{
        this.name = ''
        this.countries = ''
        this.countries_id = ''
        this.popup = false
        this.emty = false

      }
    },






    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('postFiltersCity', {params: this.params, data: this.arrFilters})
      this.listNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },


    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }



    
  },

  
  components: {
    Search, Filters, Pagination, SelectedFilters, Autocomplete
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.city)
    }
  },
}

</script>